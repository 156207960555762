import { useLocation, useRouteLoaderData } from 'react-router';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { startCase } from 'lodash-es';
import type { LoginMethod } from './session.server';

export const analytics = new AnalyticsBrowser();

export function useAnalytics() {
  const location = useLocation();
  const data = useRouteLoaderData('root') as { SESSION: { LOGIN_METHOD?: LoginMethod } };
  const isImpersonation = data?.SESSION.LOGIN_METHOD === 'IMPERSONATION';

  function actionClicked({
    target,
    element = '',
    ...props
  }: {
    target?: HTMLButtonElement | HTMLAnchorElement | EventTarget | null;
    element?: string | null;
  }) {
    if (isImpersonation) {
      return;
    }
    const payload = {
      text: '',
      location,
      destination: '',
      element,
      ...props,
    };

    if (target instanceof HTMLButtonElement || target instanceof HTMLAnchorElement) {
      payload.text = target.textContent || '';
    }

    if (target instanceof HTMLAnchorElement) {
      payload.destination = target.href;
    }

    void analytics.track('Action Clicked', payload);
  }

  function elementHovered({ element, ...props }: { element: string }) {
    if (isImpersonation) {
      return;
    }
    analytics.track('Element Hovered', {
      element,
      location,
      ...props,
    });
  }

  function handleOnCloseEvent() {
    if (isImpersonation) {
      return;
    }
    actionClicked({
      target: null,
      element: 'Closed App',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- remove this and fix code
  function onFormSubmit(event: any, pageName: any) {
    if (isImpersonation) {
      return;
    }
    const formData = new FormData(event.target as HTMLFormElement);

    const fields: Record<string, string | string[]> = {};

    formData.forEach((value, key) => {
      if (key.includes('[')) return;

      if (!Reflect.has(fields, key)) {
        fields[key] = value.toString();
        return;
      }

      if (!Array.isArray(fields[key])) {
        fields[key] = [fields[key].toString()];
      }

      if (Array.isArray(fields[key])) {
        (fields[key] as string[]).push(value.toString());
      }
    });

    fields[event.nativeEvent.submitter.name] = event.nativeEvent.submitter.value;

    const payload = {
      element: pageName,
      ...fields,
    };
    analytics.track('Form Submitted', payload);
  }

  const pathNameArray = location.pathname.split('/');
  const pageName = !isImpersonation ? startCase(pathNameArray.pop()) : '';
  let pageCategory = startCase(pathNameArray[1]);
  pageCategory = pageCategory === '' ? 'App' : pageCategory;

  return {
    actionClicked,
    elementHovered,
    handleOnCloseEvent,
    onFormSubmit,
    pageName,
    pageCategory,
  };
}
